define('jason-frontend/templates/profile', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 8
            },
            "end": {
              "line": 23,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-cal");
          var el3 = dom.createTextNode("Kalender");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-practice");
          var el3 = dom.createTextNode("Praxis");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element32 = dom.childAt(fragment, [1, 1]);
          var element33 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element32, 'class');
          morphs[1] = dom.createElementMorph(element32);
          morphs[2] = dom.createAttrMorph(element33, 'class');
          morphs[3] = dom.createElementMorph(element33);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[18,80],[18,91]]]],"cal"],[],["loc",[null,[18,76],[18,98]]]],"is-active",""],[],["loc",[null,[18,55],[18,115]]]]]]],
          ["element","action",["selectTab","cal"],[],["loc",[null,[18,133],[18,161]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[21,80],[21,91]]]],"practice"],[],["loc",[null,[21,76],[21,103]]]],"is-active",""],[],["loc",[null,[21,55],[21,120]]]]]]],
          ["element","action",["selectTab","practice"],[],["loc",[null,[21,143],[21,176]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 8
            },
            "end": {
              "line": 28,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-warehouse");
          var el3 = dom.createTextNode("Elektronische Hausapotheke");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element31 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element31, 'class');
          morphs[1] = dom.createElementMorph(element31);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[26,80],[26,91]]]],"warehouse"],[],["loc",[null,[26,76],[26,104]]]],"is-active",""],[],["loc",[null,[26,55],[26,121]]]]]]],
          ["element","action",["selectTab","warehouse"],[],["loc",[null,[26,145],[26,179]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[33,2],[33,23]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 10
              },
              "end": {
                "line": 72,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","pt0 box form-buttons");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","u-text--center col l-24/24");
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 10
              },
              "end": {
                "line": 74,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[73,12],[73,33]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 139,
                "column": 12
              },
              "end": {
                "line": 139,
                "column": 119
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-elovet.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 140,
                "column": 12
              },
              "end": {
                "line": 140,
                "column": 119
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-eltiga.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 141,
                "column": 12
              },
              "end": {
                "line": 141,
                "column": 119
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-barbie.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 12
              },
              "end": {
                "line": 142,
                "column": 117
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-pride.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 143,
                "column": 12
              },
              "end": {
                "line": 143,
                "column": 117
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-elord.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 12
              },
              "end": {
                "line": 144,
                "column": 113
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","max-height: 600px");
            dom.setAttribute(el1,"src","assets/images/misc/theme-tpe.png");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 193,
                  "column": 16
                },
                "end": {
                  "line": 199,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("style");
              var el2 = dom.createTextNode("\n                      label[for=cat-");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("]::before {\n                      background-color: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(";\n                    }\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element10,1,1);
              morphs[1] = dom.createMorphAt(element10,3,3);
              return morphs;
            },
            statements: [
              ["content","cat.id",["loc",[null,[195,36],[195,46]]]],
              ["content","cat.color",["loc",[null,[196,40],[196,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 192,
                "column": 14
              },
              "end": {
                "line": 204,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [2]);
            var element12 = dom.childAt(element11, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element11, 'class');
            morphs[2] = dom.createMorphAt(element11,1,1);
            morphs[3] = dom.createAttrMorph(element12, 'for');
            morphs[4] = dom.createMorphAt(element12,1,1);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","cat.colorHex",["loc",[null,[193,22],[193,34]]]]],[],0,null,["loc",[null,[193,16],[199,23]]]],
            ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[200,84],[200,93]]]]]]],
            ["inline","input",[],["type","checkbox","classNames","checkboxCat","val",["subexpr","@mut",[["get","category.id",["loc",[null,[201,71],[201,82]]]]],[],[]],"id",["subexpr","@mut",[["get","cat.fullId",["loc",[null,[201,86],[201,96]]]]],[],[]],"checked",["subexpr","is-cat-selected2",[["get","model.cats",["loc",[null,[201,123],[201,133]]]],["get","cat.fullId",["loc",[null,[201,134],[201,144]]]]],[],["loc",[null,[201,105],[201,145]]]]],["loc",[null,[201,18],[201,147]]]],
            ["attribute","for",["concat",[["get","cat.fullId",["loc",[null,[202,32],[202,42]]]]]]],
            ["content","cat.name",["loc",[null,[202,47],[202,59]]]]
          ],
          locals: ["cat"],
          templates: [child0]
        };
      }());
      var child9 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 210,
                "column": 18
              },
              "end": {
                "line": 214,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"id","room-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 214,
                "column": 16
              },
              "end": {
                "line": 218,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxLocation");
            dom.setAttribute(el1,"id","room-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 222,
                "column": 14
              },
              "end": {
                "line": 227,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var element9 = dom.childAt(element8, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createElementMorph(element8);
            morphs[2] = dom.createMorphAt(element8,1,1);
            morphs[3] = dom.createAttrMorph(element9, 'for');
            morphs[4] = dom.createMorphAt(element9,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[223,84],[223,93]]]]]]],
            ["element","action",["triggerRefreshRoom",""],["on","change","preventDefault",false],["loc",[null,[223,97],[223,164]]]],
            ["inline","input",[],["type","checkbox","classNames","checkboxLocation","val",["subexpr","@mut",[["get","category.id",["loc",[null,[224,76],[224,87]]]]],[],[]],"id",["subexpr","@mut",[["get","cat.fullId",["loc",[null,[224,91],[224,101]]]]],[],[]],"checked",["subexpr","is-cat-selected2",[["get","model.rooms",["loc",[null,[224,128],[224,139]]]],["get","cat.fullId",["loc",[null,[224,140],[224,150]]]]],[],["loc",[null,[224,110],[224,151]]]]],["loc",[null,[224,18],[224,153]]]],
            ["attribute","for",["concat",[["get","cat.fullId",["loc",[null,[225,32],[225,42]]]]]]],
            ["content","cat.name",["loc",[null,[225,47],[225,59]]]]
          ],
          locals: ["cat"],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 233,
                "column": 16
              },
              "end": {
                "line": 237,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"id","station-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 16
              },
              "end": {
                "line": 241,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxStation");
            dom.setAttribute(el1,"id","station-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 245,
                "column": 14
              },
              "end": {
                "line": 250,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var element7 = dom.childAt(element6, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createElementMorph(element6);
            morphs[2] = dom.createMorphAt(element6,1,1);
            morphs[3] = dom.createAttrMorph(element7, 'for');
            morphs[4] = dom.createMorphAt(element7,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","cat.color",["loc",[null,[246,84],[246,93]]]]]]],
            ["element","action",["triggerRefreshStation",""],["on","change","preventDefault",false],["loc",[null,[246,97],[246,167]]]],
            ["inline","input",[],["type","checkbox","classNames","checkboxStation","val",["subexpr","@mut",[["get","category.id",["loc",[null,[247,75],[247,86]]]]],[],[]],"id",["subexpr","@mut",[["get","cat.fullId",["loc",[null,[247,90],[247,100]]]]],[],[]],"checked",["subexpr","is-cat-selected2",[["get","model.stations",["loc",[null,[247,127],[247,141]]]],["get","cat.fullId",["loc",[null,[247,142],[247,152]]]]],[],["loc",[null,[247,109],[247,153]]]]],["loc",[null,[247,18],[247,155]]]],
            ["attribute","for",["concat",[["get","cat.fullId",["loc",[null,[248,32],[248,42]]]]]]],
            ["content","cat.name",["loc",[null,[248,47],[248,59]]]]
          ],
          locals: ["cat"],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 259,
                "column": 16
              },
              "end": {
                "line": 263,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"id","user-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.setAttribute(el1,"checked","");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 263,
                "column": 16
              },
              "end": {
                "line": 267,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("input");
            dom.setAttribute(el1,"value","9999");
            dom.setAttribute(el1,"class","checkboxUser");
            dom.setAttribute(el1,"id","user-9999");
            dom.setAttribute(el1,"type","checkbox");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 271,
                  "column": 16
                },
                "end": {
                  "line": 277,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/profile.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("style");
              var el2 = dom.createTextNode("\n                      label[for=user-");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("]::before {\n                      background-color: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(";\n                    }\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element3,1,1);
              morphs[1] = dom.createMorphAt(element3,3,3);
              return morphs;
            },
            statements: [
              ["content","user.id",["loc",[null,[273,37],[273,48]]]],
              ["content","user.color",["loc",[null,[274,40],[274,54]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 270,
                "column": 14
              },
              "end": {
                "line": 282,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/profile.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [2]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createAttrMorph(element4, 'class');
            morphs[2] = dom.createElementMorph(element4);
            morphs[3] = dom.createMorphAt(element4,1,1);
            morphs[4] = dom.createAttrMorph(element5, 'for');
            morphs[5] = dom.createMorphAt(element5,0,0);
            morphs[6] = dom.createMorphAt(element5,2,2);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[271,22],[271,35]]]]],[],0,null,["loc",[null,[271,16],[277,23]]]],
            ["attribute","class",["concat",["upper checkbox-custom checkbox-primary mb5 color-pick ",["get","user.color",["loc",[null,[278,84],[278,94]]]]]]],
            ["element","action",["triggerRefreshUser",""],["on","change","preventDefault",false],["loc",[null,[278,98],[278,165]]]],
            ["inline","input",[],["type","checkbox","classNames","checkboxUser","val",["subexpr","@mut",[["get","user.id",["loc",[null,[279,72],[279,79]]]]],[],[]],"id",["subexpr","@mut",[["get","user.fullId",["loc",[null,[279,83],[279,94]]]]],[],[]],"checked",["subexpr","is-cat-selected2",[["get","model.users",["loc",[null,[279,121],[279,132]]]],["get","user.fullId",["loc",[null,[279,133],[279,144]]]]],[],["loc",[null,[279,103],[279,145]]]]],["loc",[null,[279,18],[279,147]]]],
            ["attribute","for",["concat",["user-",["get","user.id",["loc",[null,[280,37],[280,44]]]]]]],
            ["content","user.firstname",["loc",[null,[280,49],[280,67]]]],
            ["content","user.lastname",["loc",[null,[280,68],[280,85]]]]
          ],
          locals: ["user"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 0
            },
            "end": {
              "line": 288,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1,"class","tabs__body mt20");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-password");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","mb20");
          var el4 = dom.createTextNode("Passwort ändern");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","ml20 mt20 is-highlighted mbm10");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24@tablet  l-24/24@phone gutter");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field col l-20/24");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","password");
          dom.setAttribute(el5,"class","field-label title required");
          var el6 = dom.createTextNode("Neues Passwort");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field col l-20/24");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","passwordConfirmation");
          dom.setAttribute(el5,"class","field-label title required");
          var el6 = dom.createTextNode("Passwort wiederholen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col  l-24/24@tablet  l-12/24@desk");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","box");
          var el5 = dom.createTextNode("\n            Das neue Passwort muss die folgenden Kriterien erfüllen:\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("ul");
          dom.setAttribute(el5,"id","criteria");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("li");
          var el7 = dom.createTextNode("Mindestens 8 Zeichen lang");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("li");
          var el7 = dom.createTextNode("Mindestens 1 Klein- und Großbuchstabe");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("li");
          var el7 = dom.createTextNode("Mindestens 1 Zahl");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("li");
          var el7 = dom.createTextNode("Erlaubte Sonderzeichen: @ * _ - . ! #");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-warehouse");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","user-warehouse");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Vorauswahl ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","field select");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-practice");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24 gutter");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","practice-favs");
          dom.setAttribute(el5,"class","field-label title mb10");
          var el6 = dom.createTextNode("Behandlung / Position hinzufügen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","checkbox-custom checkbox-primary field-checkbox");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","practice-favs");
          var el7 = dom.createTextNode("Favoriten standardmäßig ausgewählt");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","mt10 col l-12/24@desk l-24/24 gutter");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","landingPage");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Startseite");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-theme");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24@desk l-24/24 gutter");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","user-warehouse");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Ausgewählt");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","field select");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","gutter mt40 ml10");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Vorschau");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2,"data-for","#tab-cal");
          dom.setAttribute(el2,"id","profileCal");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","mb20");
          var el4 = dom.createTextNode("Vorausgewählte Terminart");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","user-warehouse");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Terminart");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","mb20");
          var el4 = dom.createTextNode("Vorausgewählte Filter");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24 gutter");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","patient-category");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Art");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","upper checkbox-custom checkbox-primary mb5 type-pick calApp");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","type-app");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","calApp");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Kunden");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppMa");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","type-appUser");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","calAppMa");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Mitarbeiter");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","upper checkbox-custom checkbox-primary mb5 type-pick calAppEtiga");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","type-appEtiga");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","calAppEtiga");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" App Terminslot");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","upper checkbox-custom checkbox-primary mb5 type-pick calTask");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","type-task");
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","calTask");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Aufgaben");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","patient-category");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Terminarten");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","patient-category");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Lokationen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","clear-left users");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","upper checkbox-custom checkbox-primary mb5");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","room-9999");
          var el8 = dom.createTextNode("Alle");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","field");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","patient-category");
          dom.setAttribute(el5,"class","field-label title");
          var el6 = dom.createTextNode("Stationen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","clear-left users");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","upper checkbox-custom checkbox-primary mb5");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","station-9999");
          var el8 = dom.createTextNode("Alle");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-12/24 gutter");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","col l-12/24 gutter");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","field");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6,"for","patient-category");
          dom.setAttribute(el6,"class","field-label title");
          var el7 = dom.createTextNode("Personen");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","upper checkbox-custom checkbox-primary mb5");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("label");
          dom.setAttribute(el7,"for","user-9999");
          var el8 = dom.createTextNode("Alle");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var element14 = dom.childAt(element13, [1]);
          var element15 = dom.childAt(element14, [5]);
          var element16 = dom.childAt(element13, [3]);
          var element17 = dom.childAt(element13, [5]);
          var element18 = dom.childAt(element13, [7]);
          var element19 = dom.childAt(element18, [1]);
          var element20 = dom.childAt(element19, [3]);
          var element21 = dom.childAt(element13, [9]);
          var element22 = dom.childAt(element21, [7]);
          var element23 = dom.childAt(element22, [1]);
          var element24 = dom.childAt(element23, [7]);
          var element25 = dom.childAt(element22, [5]);
          var element26 = dom.childAt(element25, [3, 1]);
          var element27 = dom.childAt(element22, [7]);
          var element28 = dom.childAt(element27, [3, 1]);
          var element29 = dom.childAt(element21, [9, 1, 1]);
          var element30 = dom.childAt(element29, [3]);
          var morphs = new Array(34);
          morphs[0] = dom.createAttrMorph(element14, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element15, [1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element15, [3]),3,3);
          morphs[3] = dom.createMorphAt(element14,9,9);
          morphs[4] = dom.createAttrMorph(element16, 'class');
          morphs[5] = dom.createMorphAt(dom.childAt(element16, [1, 1, 3]),1,1);
          morphs[6] = dom.createAttrMorph(element17, 'class');
          morphs[7] = dom.createMorphAt(dom.childAt(element17, [1, 1, 3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element17, [3, 3]),1,1);
          morphs[9] = dom.createAttrMorph(element18, 'class');
          morphs[10] = dom.createMorphAt(dom.childAt(element19, [1, 3]),1,1);
          morphs[11] = dom.createMorphAt(element20,3,3);
          morphs[12] = dom.createMorphAt(element20,5,5);
          morphs[13] = dom.createMorphAt(element20,7,7);
          morphs[14] = dom.createMorphAt(element20,9,9);
          morphs[15] = dom.createMorphAt(element20,11,11);
          morphs[16] = dom.createMorphAt(element20,13,13);
          morphs[17] = dom.createAttrMorph(element21, 'class');
          morphs[18] = dom.createMorphAt(dom.childAt(element21, [3, 3]),1,1);
          morphs[19] = dom.createMorphAt(dom.childAt(element23, [3]),1,1);
          morphs[20] = dom.createMorphAt(dom.childAt(element23, [5]),1,1);
          morphs[21] = dom.createMorphAt(element24,1,1);
          morphs[22] = dom.createMorphAt(dom.childAt(element24, [3]),2,2);
          morphs[23] = dom.createMorphAt(dom.childAt(element23, [9]),1,1);
          morphs[24] = dom.createMorphAt(dom.childAt(element22, [3]),3,3);
          morphs[25] = dom.createElementMorph(element26);
          morphs[26] = dom.createMorphAt(element26,1,1);
          morphs[27] = dom.createMorphAt(element25,5,5);
          morphs[28] = dom.createElementMorph(element28);
          morphs[29] = dom.createMorphAt(element28,1,1);
          morphs[30] = dom.createMorphAt(element27,5,5);
          morphs[31] = dom.createElementMorph(element30);
          morphs[32] = dom.createMorphAt(element30,1,1);
          morphs[33] = dom.createMorphAt(element29,5,5);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[37,56],[37,67]]]],"password"],[],["loc",[null,[37,52],[37,79]]]],"is-active",""],[],["loc",[null,[37,31],[37,96]]]]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","password",["loc",[null,[46,28],[46,36]]]]],[],[]],"autofocus",true,"id","password","type","password","class","gui-input","name","password"],["loc",[null,[46,14],[46,118]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","passwordConfirmation",["loc",[null,[50,28],[50,48]]]]],[],[]],"id","passwordConfirmation","type","password","autofocus",true,"class","gui-input","name","passwordConfirmation"],["loc",[null,[50,14],[50,153]]]],
          ["block","unless",[["get","inProgress",["loc",[null,[65,20],[65,30]]]]],[],0,1,["loc",[null,[65,10],[74,21]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[77,56],[77,67]]]],"warehouse"],[],["loc",[null,[77,52],[77,80]]]],"is-active",""],[],["loc",[null,[77,31],[77,97]]]]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","warehouses",["loc",[null,[83,24],[83,34]]]]],[],[]],"value",["subexpr","@mut",[["get","primaryWarehouseId",["loc",[null,[84,22],[84,40]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"name","primaryWarehouseId","placeholder","Keines"],["loc",[null,[82,14],[91,16]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[96,56],[96,67]]]],"practice"],[],["loc",[null,[96,52],[96,79]]]],"is-active",""],[],["loc",[null,[96,31],[96,96]]]]]]],
          ["inline","input",[],["type","checkbox","classNames","","id","practice-favs","checked",["subexpr","@mut",[["get","preselectFavorites",["loc",[null,[101,79],[101,97]]]]],[],[]]],["loc",[null,[101,14],[101,99]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","landingPages",["loc",[null,[110,22],[110,34]]]]],[],[]],"value",["subexpr","@mut",[["get","model.landingPage",["loc",[null,[111,20],[111,37]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","landingPage"],["loc",[null,[109,12],[117,14]]]],
          ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[121,56],[121,67]]]],"theme"],[],["loc",[null,[121,52],[121,76]]]],"is-active",""],[],["loc",[null,[121,31],[121,93]]]]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","themes",["loc",[null,[127,24],[127,30]]]]],[],[]],"value",["subexpr","@mut",[["get","model.theme",["loc",[null,[128,22],[128,33]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",false,"name","theme"],["loc",[null,[126,14],[134,16]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[139,22],[139,33]]]],"elovet"],[],["loc",[null,[139,18],[139,43]]]]],[],2,null,["loc",[null,[139,12],[139,126]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[140,22],[140,33]]]],"eltiga"],[],["loc",[null,[140,18],[140,43]]]]],[],3,null,["loc",[null,[140,12],[140,126]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[141,22],[141,33]]]],"barbie"],[],["loc",[null,[141,18],[141,43]]]]],[],4,null,["loc",[null,[141,12],[141,126]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[142,22],[142,33]]]],"pride"],[],["loc",[null,[142,18],[142,42]]]]],[],5,null,["loc",[null,[142,12],[142,124]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[143,22],[143,33]]]],"elord"],[],["loc",[null,[143,18],[143,42]]]]],[],6,null,["loc",[null,[143,12],[143,124]]]],
          ["block","if",[["subexpr","eq",[["get","model.theme",["loc",[null,[144,22],[144,33]]]],"tpe"],[],["loc",[null,[144,18],[144,40]]]]],[],7,null,["loc",[null,[144,12],[144,120]]]],
          ["attribute","class",["concat",["tabs__content ml0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[149,60],[149,71]]]],"cal"],[],["loc",[null,[149,56],[149,78]]]],"is-active",""],[],["loc",[null,[149,35],[149,95]]]]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[156,22],[156,39]]]]],[],[]],"value",["subexpr","@mut",[["get","calCategory",["loc",[null,[157,20],[157,31]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","optionValuePath","id","allowClear",true,"name","patientCategory","placeholder","Keine"],["loc",[null,[155,12],[164,14]]]],
          ["inline","input",[],["type","checkbox","classNames","checkboxType","val","app","id","type-app","checked",["subexpr","is-cat-selected2",[["get","model.types",["loc",[null,[173,116],[173,127]]]],"type-app"],[],["loc",[null,[173,98],[173,139]]]]],["loc",[null,[173,16],[173,141]]]],
          ["inline","input",[],["type","checkbox","classNames","checkboxType","val","user","id","type-appUser","checked",["subexpr","is-cat-selected2",[["get","model.types",["loc",[null,[177,121],[177,132]]]],"type-appUser"],[],["loc",[null,[177,103],[177,148]]]]],["loc",[null,[177,16],[177,150]]]],
          ["inline","input",[],["type","checkbox","classNames","checkboxType","val","user","id","type-appEtiga","checked",["subexpr","is-cat-selected2",[["get","model.types",["loc",[null,[181,122],[181,133]]]],"type-appEtiga"],[],["loc",[null,[181,104],[181,150]]]]],["loc",[null,[181,16],[181,152]]]],
          ["inline","button-etiga-small",[],["content","","classNames","pt2 inline-icon","size","16","width",28],["loc",[null,[182,77],[182,158]]]],
          ["inline","input",[],["type","checkbox","classNames","checkboxType","val","task","id","type-task","checked",["subexpr","is-cat-selected2",[["get","model.types",["loc",[null,[185,118],[185,129]]]],"type-task"],[],["loc",[null,[185,100],[185,142]]]]],["loc",[null,[185,16],[185,144]]]],
          ["block","each",[["get","categories",["loc",[null,[192,22],[192,32]]]]],[],8,null,["loc",[null,[192,14],[204,23]]]],
          ["element","action",["triggerRefreshRoom","all"],["on","change","preventDefault",false],["loc",[null,[209,72],[209,142]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","model.rooms",["loc",[null,[210,42],[210,53]]]],"room-9999"],[],["loc",[null,[210,24],[210,66]]]]],[],9,10,["loc",[null,[210,18],[218,23]]]],
          ["block","each",[["get","rooms",["loc",[null,[222,22],[222,27]]]]],[],11,null,["loc",[null,[222,14],[227,23]]]],
          ["element","action",["triggerRefreshStation","all"],["on","change","preventDefault",false],["loc",[null,[232,70],[232,143]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","model.stations",["loc",[null,[233,40],[233,54]]]],"station-9999"],[],["loc",[null,[233,22],[233,70]]]]],[],12,13,["loc",[null,[233,16],[241,23]]]],
          ["block","each",[["get","stations",["loc",[null,[245,22],[245,30]]]]],[],14,null,["loc",[null,[245,14],[250,23]]]],
          ["element","action",["triggerRefreshUser","all"],["on","change","preventDefault",false],["loc",[null,[258,70],[258,140]]]],
          ["block","if",[["subexpr","is-cat-selected2",[["get","model.users",["loc",[null,[259,40],[259,51]]]],"user-9999"],[],["loc",[null,[259,22],[259,64]]]]],[],15,16,["loc",[null,[259,16],[267,23]]]],
          ["block","each",[["get","users",["loc",[null,[270,22],[270,27]]]]],[],17,null,["loc",[null,[270,14],[282,23]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 291,
              "column": 4
            },
            "end": {
              "line": 295,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["save"],[],["loc",[null,[292,9],[292,26]]]],
          ["inline","button-save",[],["color","#ffffff","size","54"],["loc",[null,[293,8],[293,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 296,
              "column": 4
            },
            "end": {
              "line": 300,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","submit");
          dom.setAttribute(el2,"style","border:0;padding:0");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-save",[],["color","#ffffff","size","54"],["loc",[null,[298,57],[298,98]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 301,
              "column": 4
            },
            "end": {
              "line": 305,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveWarehouse"],[],["loc",[null,[302,9],[302,35]]]],
          ["inline","button-save",[],["color","#ffffff","size","54"],["loc",[null,[303,8],[303,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 4
            },
            "end": {
              "line": 310,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/profile.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["savePractice"],[],["loc",[null,[307,9],[307,34]]]],
          ["inline","button-save",[],["color","#ffffff","size","54"],["loc",[null,[308,8],[308,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 361,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/profile.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card animated");
        dom.setAttribute(el1,"id","calProfileTab");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","box");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","u-mb0");
        var el4 = dom.createTextNode("Profil");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("form");
        dom.setAttribute(el2,"class","admin-form");
        dom.setAttribute(el2,"method","post");
        dom.setAttribute(el2,"id","expiryForm");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tabs__head");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","u-mb0 clearfix");
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-theme");
        var el8 = dom.createTextNode("Farbschema");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-password");
        var el8 = dom.createTextNode("Passwort ändern");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n        ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("      ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","action-icons");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"#expiryForm\").validate({\n      errorClass: \"state-error\",\n      validClass: \"state-success\",\n      errorElement: \"em\",\n      rules: {\n        'password': {\n          required: true,\n          pwcheck: true,\n        },\n        'passwordConfirmation': {\n          equalTo: '#password'\n        },\n      },\n      messages: {\n        password: {\n          pwcheck: \"Das Passwort entspricht nicht den Kriterien!\"\n        }\n      },\n      highlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n      },\n      unhighlight: function(element, errorClass, validClass) {\n        $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n      },\n      errorPlacement: function(error, element) {\n        if (element.is(\":radio\") || element.is(\":checkbox\")) {\n          element.closest('.option-group').after(error);\n        } else {\n          error.insertAfter(element.parent());\n        }\n      }\n    });\n\n    $.validator.addMethod(\"pwcheck\",\n      function(value, element) {\n        return /^[A-Za-z0-9\\d=!\\-@._*#]*$/.test(value) // consists of only these\n          && /[a-z]/.test(value) // has a lowercase letter\n          && /[A-Z]/.test(value) // has a lowercase letter\n          && /\\d/.test(value) // has a digit\n          && value.length >= 8  // at least 8\n      });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element34 = dom.childAt(fragment, [0, 3]);
        var element35 = dom.childAt(element34, [1]);
        var element36 = dom.childAt(element35, [1, 1]);
        var element37 = dom.childAt(element36, [1, 1]);
        var element38 = dom.childAt(element36, [3, 1]);
        var element39 = dom.childAt(element34, [3]);
        var morphs = new Array(12);
        morphs[0] = dom.createElementMorph(element34);
        morphs[1] = dom.createAttrMorph(element37, 'class');
        morphs[2] = dom.createElementMorph(element37);
        morphs[3] = dom.createAttrMorph(element38, 'class');
        morphs[4] = dom.createElementMorph(element38);
        morphs[5] = dom.createMorphAt(element36,5,5);
        morphs[6] = dom.createMorphAt(element36,6,6);
        morphs[7] = dom.createMorphAt(element35,3,3);
        morphs[8] = dom.createMorphAt(element39,1,1);
        morphs[9] = dom.createMorphAt(element39,2,2);
        morphs[10] = dom.createMorphAt(element39,3,3);
        morphs[11] = dom.createMorphAt(element39,4,4);
        return morphs;
      },
      statements: [
        ["element","action",["changeExpired"],["on","submit"],["loc",[null,[6,58],[6,96]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[11,78],[11,89]]]],"theme"],[],["loc",[null,[11,74],[11,98]]]],"is-active",""],[],["loc",[null,[11,53],[11,115]]]]]]],
        ["element","action",["selectTab","theme"],[],["loc",[null,[11,135],[11,165]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[14,78],[14,89]]]],"password"],[],["loc",[null,[14,74],[14,101]]]],"is-active",""],[],["loc",[null,[14,53],[14,118]]]]]]],
        ["element","action",["selectTab","password"],[],["loc",[null,[14,141],[14,174]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[16,14],[16,42]]]]],[],0,null,["loc",[null,[16,8],[23,15]]]],
        ["block","if",[["subexpr","has-permission",["ehapo"],[],["loc",[null,[24,14],[24,38]]]]],[],1,null,["loc",[null,[24,8],[28,15]]]],
        ["block","if",[["get","showLoading",["loc",[null,[32,6],[32,17]]]]],[],2,3,["loc",[null,[32,0],[288,7]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectedTab",["loc",[null,[291,18],[291,29]]]],"theme"],[],["loc",[null,[291,14],[291,38]]]],["subexpr","eq",[["get","selectedTab",["loc",[null,[291,43],[291,54]]]],"cal"],[],["loc",[null,[291,39],[291,61]]]]],[],["loc",[null,[291,10],[291,62]]]]],[],4,null,["loc",[null,[291,4],[295,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedTab",["loc",[null,[296,14],[296,25]]]],"password"],[],["loc",[null,[296,10],[296,37]]]]],[],5,null,["loc",[null,[296,4],[300,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedTab",["loc",[null,[301,14],[301,25]]]],"warehouse"],[],["loc",[null,[301,10],[301,38]]]]],[],6,null,["loc",[null,[301,4],[305,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedTab",["loc",[null,[306,14],[306,25]]]],"practice"],[],["loc",[null,[306,10],[306,37]]]]],[],7,null,["loc",[null,[306,4],[310,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});